<main class="page container">
    <header>
        <h5 class="page-subtitle">A designer who can code</h5>
        <h2 class="page-title">About Me</h2>
    </header>
    <div class="full-width hero-image"></div>

    <article class="summary">
        <div class="details">
            <div>
                <h4>Occupation</h4>
                <p class="small-text">UX and Digital Designer</p>
            </div>
            <div>
                <h4>Education </h4>
                <p class="small-text">Bath University, Imperial College London</p>
            </div>
            <div>
                <h4>Notable Employers</h4>
                <p class="small-text">Thoughtworks, Amnesty International</p>
            </div>
            <div>
                <h4>Currently</h4>
                <p class="small-text">Employee Experience for a scale-up</p>
            </div>
        </div>
    </article>
        <article class="chapter">
            <!-- <div class="chapter-headings">
                <h2 class="chapter-title">
                    My Life
                </h2>
            </div>
            <div class="chapter-content">
                <p> I was born in the Madrid <i>Extrarradio</i>, in the town of Leganés, whose claim to fame is <a href="https://en.wikipedia.org/wiki/Legan%C3%A9s#Culture" target="_blank"> a street named after the band AC/DC.</a> My school years were spent filling the margins of my notebooks with drawings and spacing out in class (unless it was science or P.E.).
                    <br>
                    Eventually, I taught myself English by reading Harry Potter and moved to England, where I went to university to study Natural Sciences (which involved a mix of Biology, Programming, and HCI). 
                    <br>
                    It was a high point in my life when I got a scholarship to do a Master's degree, and a low when I graduated from it straight into the middle of a financial crisis. However, I quickly got a job as a technical consultant in London, as you usually do after studying science. Being a consultant made me tough and taught me to deal with clients. 
                </p>
            </div>
            <div class="chapter-image">
                <img src="/assets/img/about/london.jpg" alt="">
            </div> -->
            <div class="chapter-content">
                <p>
                    <br>
                    Early in my career as a developer, I gravitated towards UX because I was more interested in the people in front of the screen than the algorithms behind it. Eventually I became a designer who can code or a coder who can design. Although not necessarily both at the same time. I have worked for several startups within the renewables industry and for Amnesty International. 
                    At the moment, I'm applying my UX toolbox in the People & Culture department of a rapidly growing company, to strategically design internal company processes.
                    <br>
                </p>
            </div>
            <div class="chapter-image">
                <img src="/assets/img/about/sketchnoting.jpg" alt=""> 
            </div>
            <!-- <div class="chapter-content">
                <p>
                I now call Copenhagen my home, which means that every time I’ve moved cities, I’ve moved northwards. I fear that I’ll retire somewhere really cold.
                 <br>
            </div>
            <div class="chapter-image">
                <img src="/assets/img/about/cph.jpg" alt=""> 
            </div>
            <div class="chapter-content">
                <p>
                In my free time, I like to do Yoga, play Football, draw comics, watch sad films d’auteur, and constantly rework my portfolio. 
                </p>
            </div>  -->
    </article>
