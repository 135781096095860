<div class="main-container">
    <div class="filters">
        <h5>
            Selected Projects
        </h5>
    </div>
    <!-- <div class="case-study" routerLink="piclo"> -->
    <div class="case-study" (click)="navigateToCaseStudy('piclo')">
        <div class="description">
            <h5 class="page-subtitle">product design</h5>
            <h5>Designing a marketplace for energy flexibility</h5>
            <h2 class="page-title">PicloFlex</h2>
            
        </div>
        <div id="piclo_thumb" class="thumb center-cropped" style="background-image: url('assets/img/piclo/piclo_thumb.png');">
        </div>
    </div>
    <div class="case-study reverse" routerLink="seaborg">
        <div class="thumb center-cropped" style="background-image: url('assets/img/seaborg/Earth_Atom.jpg');">
            <!-- <img src="assets/img/seaborg/thumb.png" alt="seaborg_thumb"> -->
        </div>
        <div class="description">
            <h5 class="page-subtitle">web design</h5>
            <h5>Designing a website to redefine nuclear energy</h5>
            <h2 class="page-title">Seaborg Technologies</h2>
        </div>
    </div>
    <div class="case-study" routerLink="amnestyinternational">
        <div class="description">
            <h4>UX</h4>
            <h5>User Experience Design for a global NGO</h5>
            <h2 class="page-title">Amnesty International</h2>
        </div>
        <div class="thumb center-cropped" style="background-image: url('assets/img/amnesty/amnesty_thumb.png');">
        </div>
    </div>
    <div class="case-study reverse" routerLink="shapeshift">
        <div class="description">
            <h5 class="page-subtitle">brand</h5>
            <h5>A Gender-Neutral Makeup Range</h5>
            <h2 class="page-title">ShapeShift</h2>
        </div>
        <div class="thumb center-cropped" style="background-image: url('assets/img/shapeshift/shapeshift_thumb.png');">
            <!-- <img src="assets/img/shapeshift/shapeshift_thumb.png" alt="shapeshift_cover"> -->
        </div>
    </div>
    <!-- <div class="case-study" routerLink="vitala">
        <div class="description">
            <h4>05</h4>
            <h5 class="page-subtitle">Designing a website & digital identity for women's sexual health </h5>
            <h2 class="page-title">Vitala Global</h2>
        </div>
        <div class="thumb center-cropped" style="background-image: url('assets/img/vitala/illust.png');">
        </div>
    </div> -->
    <!-- <div class="case-study" routerLink="forecast">
        <div class="description">
            <h4>05</h4>
            <h5 class="page-subtitle">A Design team of one</h5>
            <h2 class="page-title">Forecast</h2>
        </div>
        <div class="thumb center-cropped" style="background-image: url('assets/img/forecast/forecast_thumb.png');">
        </div>
    </div> -->
    
</div>
