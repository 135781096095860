<main class="page container">
    <header>
        <h5 class="page-subtitle">User Experience Design for a global NGO</h5>
        <h2 class="page-title">Amnesty International</h2>
    </header>
    <div class="full-width hero-image"></div>

    <article class="summary">
        <div class="details">
            <div>
                <h4>Role</h4>
                <p class="small-text">UX Designer</p>
            </div>
            <div>
                <h4>Team</h4>
                <p class="small-text">Design Studio</p>
            </div>
            <div>
                <h4>Methods</h4>
                <p class="small-text">Research | Service Design</p>
            </div>
            <div>
                <h4>Live Site</h4>
                <p class="small-text">www.amnesty.org</p>
            </div>
        </div>
        <h1 class="intro-title">My Contract</h1>
        <p class="intro-text">
            I worked 6 months at the Amnesty International Secretariat (IS) in London as a UX Designer. I was part of the Design Team, which produced global assets and nest practices recommendations for the rest of the organisation, as well as provided UX and design help to other internal departments.
        </p>
    </article>
    <div class="final-product">
        <img src="/assets/img/amnesty/final-product.png" alt="final product shot">
    </div>
    <article class="chapter accent">
        <div class="chapter-headings">
            <h2 class="chapter-title">
            Project
            </h2>
            <h3 class="chapter-subtitle">
                WordPress Microsites for Sections
            </h3>
        </div>
        <div class="chapter-content">
            <p>
                Amnesty’s International Secretariat was championing a move to a global component-based WordPress template to maintain consistency in their regional sites. My role in this was: </p>
                <ul>
                    <li>Supporting the Digital Team in categorising and testing components.</li>
                    <li>UX audits with members from regional offices</li>
                    <li>
                        Collaborating with the Content Manager on the user guide.</li>
                </ul>
            
        </div>
        
    </article>
    <article class="chapter">
        <div class="chapter-headings">
            <h2 class="chapter-title">
                WordPress sites for sections
            </h2>
            <h3 class="chapter-subtitle">
                UX audits
            </h3>
        </div>
    
        <div class="chapter-content">
            <p>
                Amnesty IS was championing a move to a global component-based WordPress template. The UX team had to create a series of components and test them with international members in order to make sure they fulfilled all the functionality required.
            </p>
        </div>
        <div class="chapter-image">
          <img src="assets/img/amnesty/ux-audits.png" alt="UX audits">
        </div>
    </article>
    <article class="chapter accent">
        <div class="chapter-headings">
            <h2 class="chapter-title">
                Donate Page
            </h2>
            <h3 class="chapter-subtitle">
                Restructuring the global site
            </h3>
        </div>
        <div class="chapter-image">
            <img src="assets/img/amnesty/donate-page.png" alt="">
        </div>
        <div class="chapter-content">
            <p>
            The global site was also being restructured  using the WordPress theme. Here is the Donate Page redesign, and what kinds of questions I raised:
            <br>
            - How and when to show where donor’s money go - A/B tests performed.
            <br>
            - Highlighting success stories
            <br>
            - Donate box design.
            </p>
        </div>
    </article>
    <article class="chapter">
        <div class="chapter-headings">
            <h2 class="chapter-title">
                Working at the Secretary General Office
            </h2>
            <h3 class="chapter-subtitle">
                SecGen Briefings
            </h3>
        </div>
        <div class="chapter-content">
            <p>
                How do we optimise the process of briefing the Secretary General ahead of meetings?
The secretary general attends many meetings and has to be briefed on the las t minute. Many teams are involved in creating these briefs. The whole activity was disorganised and chaotic. The SecGen office apporached the design team to help them design better briefs. 
Mapping the process highligted:
Opportunities to gather information at an early stage
Compiled the questions and information that the sec gen needed and created a template to be filled beforehand.
            </p>
        </div>
    <div class="chapter-image">
      <img src="assets/img/amnesty/secgen-process.png" alt="Service blueprint for the briefing process">
    </div>
    </article>
    <article class="chapter">
        <div class="chapter-headings">
            <h2 class="chapter-title">
                Visual Design
            </h2>
            <h3 class="chapter-subtitle">
                Designing assets for the comms department
            </h3>
        </div>
    <div class="chapter-image">
      <img src="assets/img/amnesty/instagram-icons.png" alt="">
    </div>
    <div class="chapter-content">
        <p>
            Following the guidance of the senior designer, who maintains an extensive library of icons representing all kids of human rights issues.
        </p>
    </div>
       <div class="chapter-image">
         <img src="assets/img/amnesty/ig-templates.png" alt="instagram templates">
       </div> 
    </article>
</main>
