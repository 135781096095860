import { Component, OnInit } from '@angular/core';
import anime from 'animejs/lib/anime.es.js';

@Component({
  selector: 'app-shapeshift',
  templateUrl: './shapeshift.component.html',
  styleUrls: ['./shapeshift.component.scss']
})
export class ShapeshiftComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    const picture = document.querySelector('.hero-image');
    const timeline = anime.timeline ({
      duration: 2000,
    });

    timeline.add({
      targets: picture,
      height:'100vh',
      scale: 0.1,
      translateY: '-25vh',
      easing: 'linear',
      duration: 0,
    
    });
    
    timeline.add({
      targets: picture,
      height: {
        value: '50vh',
        duration: 1200
      },
      scale: {
        value: 1,
        duration: 400
      },
      translateY: {
        value: 0,
        duration: 1200
      },
      easing: 'easeOutQuad',
    });
  }

}
