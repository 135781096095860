<div class="container">
    <div class="title">
        <h1>Aurora</h1>
    </div>
    <div class="headline">
        <h2 id="s1"></h2>
    </div>
    <div class="animation" id="sunset">
        <svg height="100%" width='100%' viewBox="0 0 100 100">
            <!-- <rect width='100' height='100' x='0' y='0' fill="red" /> -->
            <circle id="sun" cx="50" cy="120" r="40" stroke="black" stroke-width="0.5" fill="#f9cc12"/>

            <rect id="sea" width='300' height='2000' x='-100' y='75' stroke="black" stroke-width="0.5" fill="#617ef2" />
            
            <g id="moon" stroke="none" stroke-width="0.5" fill="none" fill-rule="evenodd" style="transform: translateX(100px) translateY(100px)">
                <g id="Artboard" transform="translate(0.000000, -2.000000)" fill="#FFFFFF" stroke="#000000">
                    <path d="M11.6567706,4.43762193 C4.79734584,8.89504526 0.5,16.5608382 0.5,24.994622 C0.5,38.5285926 11.4690477,49.5 25,49.5 C38.5309523,49.5 49.5,38.5285926 49.5,24.994622 C49.5,21.2625229 48.6655864,17.6501546 47.0804036,14.3627299 C46.9131542,14.0158805 46.737752,13.6731115 46.5543812,13.334692 C46.6335192,14.0457722 46.6734694,14.7643565 46.6734694,15.4881206 C46.6734694,26.1218056 38.0549749,34.7421541 27.4234694,34.7421541 C16.7919639,34.7421541 8.17346939,26.1218056 8.17346939,15.4881206 C8.17346939,11.4642124 9.41310895,7.63383206 11.6567706,4.43762193 Z" id="Combined-Shape"></path>
                </g>
            </g>
        </svg>
        
    </div>

    <!-- <div class="portfolio">
        <h3>See my Work</h3>
    </div>
    <div class="portfolio-picture">
        
    </div>
    <div class="about">
        <h3>About Me</h3>
    </div>
    <div class="about-picture">
        
    </div>
    <div class="footer"></div> -->
</div>
