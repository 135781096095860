<div id="mySidenav" class="sidenav">
  <a class="closebtn" (click)="closeNav()">&times;</a>
  <a routerLink="work" (click)="closeNav()">Work</a>
  <a routerLink="about" (click)="closeNav()">About</a>
  <!-- <a routerLink="approach" (click)="closeNav()">Approach</a> -->
  <!-- <a routerLink="contact" (click)="closeNav()">Contact</a> -->
</div>

<!-- Element to open nav -->
<div id="burger-container">
  <span class="logo-mobile">
    <a  routerLink="home" class="nav-link" routerLinkActive="active-link" href=""><img src="assets/img/logo.svg" alt=""></a>
  </span>
  <span id="burger" (click)="openNav()">=</span>
</div>


<div class="main-container">
  <nav>
    <div class="logo">
      <svg routerLink="home" class="nav-link" routerLinkActive="active-link" height="100%" width='100%' viewBox="0 0 100 100">
        <circle id="logo" cx="50" cy="50" r="47" stroke="black" stroke-width="3" fill="#F9CC12"/>
      </svg>

    </div>
    <ul>
      <li 
        routerLink="work"
        class="nav-link"
        routerLinkActive="active-link">Work</li>
      <li 
        routerLink="about"
        class="nav-link"
        routerLinkActive="active-link">About</li>
      <!-- <li 
        routerLink="approach"
        class="nav-link"
        routerLinkActive="active-link">Approach</li> -->
      <!-- <li 
        routerLink=""
        class="nav-link"
        routerLinkActive="active-link">Contact</li> -->
    </ul>
  </nav>
  <div class="main-content" data-scroll-container>
    <router-outlet></router-outlet>
  </div>
  <div class="social-media">
    <!-- <img src="assets/img/social-media/behance-pink.svg" alt=""> -->
    <a href="mailto:aurorasmelchor@gmail.com"><img src="assets/img/social-media/email-outline.svg" alt="email"></a>
    <a href="https://www.instagram.com/lacabrastudio/" target="_blank"><img src="assets/img/social-media/insta-outline.svg" alt="insta"></a>
    <a href="https://www.linkedin.com/in/aurorasuriel/" target="_blank"> <img src="assets/img/social-media/linkedin-outline.svg" alt=""></a>
  </div>
</div>
