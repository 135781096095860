<main class="page container">
    <header>
        <h5 class="page-subtitle">Visual ID and website for a woman's sexual health NGO</h5>
        <h2 class="page-title">Vitala Global</h2>
    </header>
    <div class="full-width hero-image"></div>
    <article class="summary">
        <div class="details">
            <div>
                <h4>Project Type</h4>
                <p class="small-text">Branding and Digital</p>
            </div>
            <div>
                <h4>My Role</h4>
                <p class="small-text">Brand Designer & Illustrator</p>
            </div>
            <div>
                <h4>My Team</h4>
                <p class="small-text">La Cabra Studio</p>
            </div>
            <div>
                <h4>Live Site</h4>
                <p class="small-text"><a href="https://www.vitalaglobal.org/" target="_blank">----</a></p>
            </div>
        </div>
        <h1 class="intro-title">The Project</h1>
        <p class="intro-text">Vitala Global is an organisation that supports women and girls' access to sexual and reproductive health tools in regions where there is little or no support for this. Our role in this project was to provide the full website design, a visual identity,a s well as copywriting and beand strategy support.</p>
    </article>
    <figure>
        <div class="full-width website"></div>
    </figure>
    <article class="chapter accent">
        <div class="chapter-headings">
            <h2 class="chapter-title">
                Icon & Logo
            </h2>
        </div>
        <div class="chapter-image">
            <img src="/assets/img/vitala/logos.png" alt="final product shot">
        </div>
    </article>
    <figure>
        <div class="full-width insta"></div>
    </figure>
    <article class="chapter accent">
        <div class="chapter-headings">
            <h2 class="chapter-title">
                Original Illustration
            </h2>
        </div>
        <div class="chapter-image">
            <img src="/assets/img/vitala/illust.png" alt="final product shot">
        </div>
    </article>
</main>

