<main class="page container">
    <header>
        <h5 class="page-subtitle">Branding for a Gender-Neutral Makeup Subscription</h5>
        <h2 class="page-title">ShapeShift</h2>
    </header>
    <div class="full-width hero-image"></div>
    <article class="summary">
        <div class="details">
            <div>
                <h4>Project Type</h4>
                <p class="small-text">Branding</p>
            </div>
            <div>
                <h4>My Team</h4>
                <p class="small-text">Individual Coursework</p>
            </div>
            <div>
                <h4>My Role</h4>
                <p class="small-text">Branding</p>
            </div>
            <div>
                <h4>Campaign Site</h4>
                <p class="small-text"><a href="" target="_blank">----</a></p>
            </div>
        </div>
        <h1 class="intro-title">The Project</h1>
        <p class="intro-text">Shapeshift is a concept for a gender neutral makeup subscription service with the mission of disrupting the accepted ideas about makeup and make it accessible for all. It aims to be subversive yet sleek, and provides a transformative experience for people who are seeking to break with stereotypes. </p>
    </article>
    <figure>
        <div class="full-width postcards"></div>
    </figure>
    <article class="chapter accent">
        <div class="chapter-headings">
            <h2 class="chapter-title">
                Icon & Logo
            </h2>
        </div>
        <div class="chapter-image">
            <img src="/assets/img/shapeshift/logo.png" alt="final product shot">
        </div>
    </article>
    <figure>
        <div class="full-width box-jar"></div>
    </figure>
    <div class="image-chapter accent">
        <picture>
            <source media="(max-width:800px)" srcset="assets/img/shapeshift/packaging-mobile.png">
            <source media="(min-width:800px)" srcset="assets/img/shapeshift/packaging-desk.jpg">
            <img src="assets/img/shapeshift/packaging-desk.jpg" alt="" width="100%">
        </picture>
    </div>
    <div class="image-chapter">
        <picture>
            <source media="(max-width:800px)" srcset="assets/img/shapeshift/webiste-mobile.png">
            <source media="(min-width:800px)" srcset="assets/img/shapeshift/website.jpg">
            <img src="assets/img/shapeshift/website.jpg" alt="" width="100%">
        </picture>
    </div>
    <div class="image-chapter">
        <picture>
            <source media="(max-width:800px)" srcset="assets/img/shapeshift/binary.jpg">
            <source media="(min-width:800px)" srcset="assets/img/shapeshift/binary-desk.jpg">
            <img src="assets/img/shapeshift/binary-desk.jpg" alt="" width="100%">
        </picture>
    </div>
    <article class="quote">
        <q>
            ShapeShift's tone of voice is witty, unapologetic and to-the-point.  
        </q>
    </article>
    <article class="chapter">
        <div class="chapter-headings">
            <h2 class="chapter-title">
                Campaign
            </h2>
            <h3 class="chapter-subtitle">
                Makeup testimonials
            </h3>
        </div>
        <div class="chapter-content">
            <p>
                The launching campaign for the makeup was a microsite with real testimonials from queer and non-binary people explaining their relationship with makeup. I build the site using pure HTML, CSS and Javascript. You can find it <a href=""> here</a>
            </p>
        </div>
    </article>
    <div class="image-chapter">
       
        <video controls autoplay preload loop>
            <source src="assets/img/shapeshift/Shapeshift.webm" type="video/webm">
            <source src="assets/img/shapeshift/Shapeshift.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video> 
          
        <picture>
            <source media="(max-width:800px)" srcset="assets/img/shapeshift/microsite.jpg">
            <source media="(min-width:800px)" srcset="assets/img/shapeshift/microsite.jpg">
            <img src="assets/img/shapeshift/microsite.jpg" alt="" width="100%">
        </picture>
    </div>
    <div class="image-chapter">
        <picture>
            <source media="(max-width:800px)" srcset="assets/img/shapeshift/closing.jpg">
            <source media="(min-width:800px)" srcset="assets/img/shapeshift/closing.jpg">
            <img src="assets/img/shapeshift/closing.jpg" alt="" width="100%">
        </picture>
    </div>
</main>
